import VueRouter from 'vue-router'

import Index from '../pages/Index'
import p1 from '../pages/p1'
import p2 from '../pages/p2'
import p3 from '../pages/p3'
import p4 from '../pages/p4'

export default new VueRouter({
    routes:[
        {
            path:'/',
			name: 'Index',
            component:Index,
			meta:{
                // 页面标题title
                title: '定制套餐 - Conspit'
            }
        },
		{
		    path:'/p1',
			name: 'p1',
		    component:p1,
			meta:{
			    // 页面标题title
			    title: 'GT入门款 - Conspit'
			}
		},
        {
		    path:'/p2',
			name: 'p2',
		    component:p2,
			meta:{
			    // 页面标题title
			    title: 'GT进阶款 - Conspit'
			}
		},
		{
		    path:'/p3',
			name: 'p3',
		    component:p3,
			meta:{
			    // 页面标题title
			    title: '方程式套装 - Conspit'
			}
		},
		{
		    path:'/p4',
			name: 'p4',
		    component:p4,
			meta:{
			    // 页面标题title
			    title: 'F1 座舱款 - Conspit'
			}
		},
    ]
})
<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
	
	.el-carousel__container {
		height: 370px !important;
	}
</style>

<template>
	<div>
		<div class="header">
			<div class="header-main">
				<a class="header-logo" href="http://cn.conspit.com/"></a>
				<div class="header-menu">
					<div class="header-item has2-header">
						<div class="header-span" @mouseenter="enterOne" @mouseleave="leaveOne">
							踏板
						</div>
					</div>

					<div class="header-item has2-header">
						<div class="header-span" @mouseenter="enterTwo" @mouseleave="leaveTwo">周边生态</div>
					</div>

					<div class="header-item has2-header">
						<div class="header-span" @mouseenter="enterThree" @mouseleave="leaveThree">支架座椅系列</div>
					</div>

					<!-- <div class="header-item">
						<a class="header-a">方向盘</a>
					</div> -->
					<div class="header-item has2-header">
						<div class="header-span" @mouseenter="enterFour" @mouseleave="leaveFour">座舱&环幕</div>
					</div>

					<div class="header-item">
						<a href="http://store.conspit.com/" class="header-a" style="color:#b74231;">定制套餐</a>
					</div>
					<div class="header-item">
						<a class="header-a">合作伙伴</a>
					</div>
					<div class="header-item">
						<a href="http://cn.conspit.com/download.html" class="header-a">下载</a>
					</div>
				</div>
				<div class="header-search-btn">
					<a href="https://www.conspit.com">EN</a>
					<a class="islan">中文</a>
				</div>
			</div>
		</div>
		<!--2级菜单-->

		<div class="header-view-one" v-if="one" @mouseenter="enterOne" @mouseleave="leaveOne">
			<a href="http://cn.conspit.com/info/cgp.html">CGP 压力踏板</a>
			<a href="http://cn.conspit.com/info/cpp.html">CPP 液压踏板</a>
		</div>

		<div class="header-view-two" v-if="two" @mouseenter="enterTwo" @mouseleave="leaveTwo">
			<a href="http://cn.conspit.com/info/ss.html">H2 液压手刹</a>
			<a>序列档</a>
			<a>Conspit地毯</a>
			<a>Glow2 尾灯</a>
		</div>

		<div class="header-view-three" v-if="three" @mouseenter="enterThree" @mouseleave="leaveThree">
			<a href="http://cn.conspit.com/info/cfp.html">方程式支架</a>
			<a href="http://cn.conspit.com/info/gt.html">GT支架</a>
			<a>座椅</a>
		</div>

		<div class="header-view-four" v-if="four" @mouseenter="enterFour" @mouseleave="leaveFour">
			<a href="http://cn.conspit.com/info/zc.html">F1 Pro座舱</a>
			<a>F1 Lite座舱</a>
			<a href="http://cn.conspit.com/info/halo.html">Halo 270环幕</a>
		</div>
		<!--2级菜单 end-->
		

	</div>
</template>

<script>
	export default {
		data() {
			return {
				one: false,
				two: false,
				three: false,
				four: false
			}
		},
		methods: {
			enterOne() {
				this.one = true
			},
			leaveOne() {
				this.one = false
			},
			enterTwo() {
				this.two = true
			},
			leaveTwo() {
				this.two = false
			},
			enterThree() {
				this.three = true
			},
			leaveThree() {
				this.three = false
			},
			enterFour() {
				this.four = true
			},
			leaveFour() {
				this.four = false
			}
		}
	};
</script>

<style>
	::v-deep .popper__arrow:hover {
		background: pink;
	}

	::v-deep .el-dropdown-menu .el-popper:hover {
		color: pink;
	}

	::v-deep .el-dropdown-menu__item {
		color: pink !important;
	}

	* {
		padding: 0;
		margin: 0;
		border: 0;
		font-family: "微软雅黑";
	}

	html,
	body {
		width: 100%;
		height: 100%;
	}

	a {
		text-decoration: none;
	}

	.header {
		width: 100%;
		height: 70px;
		background-color: #fff;
		position: relative;
	}

	.header-main {
		width: 1200px;
		height: 70px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		background-color: #fff;
	}

	.header-logo {
		width: 160px;
		height: 70px;
		float: left;
		background-image: url(../assets/head_logo.png);
		background-size: 160px 25px;
		background-repeat: no-repeat;
		background-position: center;
	}

	.header-menu {
		width: 985px;
		display: flex;
		height: 30px;
		float: left;
		cursor: default;
	}

	.header-item {
		display: inline-block;
		height: 70px;
		margin-left: 50px;
		position: relative;
	}

	.header-span {
		display: inline-block;
		height: 70px;
		line-height: 70px;
		color: #303233;
		font-size: 20px;
		font-weight: bold;
		cursor: pointer;
	}

	.header-menu .header-item .header-a {
		display: inline-block;
		height: 70px;
		line-height: 70px;
		color: #303233;
		font-size: 20px;
		font-weight: bold;
		transition: all 0.4s;
		cursor: pointer;
	}

	.header-menu .header-item .header-a:hover {
		color: rgb(183, 66, 49);
		transition: all 0.4s;
	}

	.header-view,
	.header-view-one,
	.header-view-two,
	.header-view-three,
	.header-view-four {
		width: 110px;
		background-color: #f2f2f2;
		position: absolute;
		top: 70px;
		z-index: 99;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
		/* display: none; */
		cursor: pointer;
	}

	.header-view-one {
		left: calc((100% - 1200px)/2 + 210px);
	}

	.header-view-two {
		left: calc((100% - 1200px)/2 + 300px);
	}

	.header-view-three {
		left: calc((100% - 1200px)/2 + 430px);
	}

	.header-view-four {
		left: calc((100% - 1200px)/2 + 600px);
	}

	.header-view a,
	.header-view-one a,
	.header-view-two a,
	.header-view-three a,
	.header-view-four a {
		display: block;
		cursor: pointer;
		height: 44px;
		line-height: 44px;
		text-indent: 6px;
		color: #333;
		font-size: 14px;
		border-bottom: 1px solid #fff;
		transition: all 0.4s;
	}

	.header-view a:hover,
	.header-view-one a:hover,
	.header-view-two a:hover,
	.header-view-three a:hover,
	.header-view-four a:hover {
		color: rgb(183, 66, 49);
		transition: all 0.4s;
	}

	.header-search-btn {
		height: 24px;
		float: left;
		background-color: #007aff;
		font-size: 0;
		margin-top: 23px;
		border: 1px solid #b74231;
	}

	.header-search-btn a {
		display: inline-block;
		font-size: 10px;
		padding: 0 3px;
		height: 24px;
		line-height: 24px;
		background-color: #fff;
		color: #b74231;
	}

	.islan {
		background-color: #b74231 !important;
		color: #fff !important;
	}

	.header2 {
		width: 100%;
		height: 70px;
		background-color: rgba(49, 51, 59, 1);
		position: relative;
		left: 0;
		top: 0;
		z-index: 10;
		z-index: -1;
	}

	.header2_back {
		width: 100%;
		height: 70px;
		display: none;
	}

	.header2-main {
		width: 1200px;
		height: 70px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}

	.header2-title {
		display: inline-block;
		height: 70px;
		line-height: 70px;
		font-size: 18px;
		color: #fff;
	}

	.header2-title2 {
		display: inline-block;
		height: 70px;
		line-height: 70px;
		font-size: 20px;
		letter-spacing: 2px;
		color: #fff;
	}

	.header2-title2 span {
		letter-spacing: 1px;
	}

	.header2-download {
		display: inline-block;
		width: 120px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		border-radius: 5px;
		background-color: #b74231;
		color: #fff;
		font-size: 16px;
		letter-spacing: 1px;
		float: right;
		margin: 18px 0;
		transition: all 0.3s;
	}

	.header2-download:hover {
		background-color: #da5946;
		transition: all 0.3s;
	}

	.header2-lab {
		width: 240px;
		height: 70px;
		float: left;
		background-repeat: no-repeat;
		/* background-size: auto 30px; */
		background-position: 0px center;
	}

	.header2-link {
		float: right;
		display: inline-block;
		height: 20px;
		line-height: 20px;
		font-size: 16px;
		margin: 25px 0;
		margin-right: 55px;
		color: #fff;
		letter-spacing: 2px;
	}

	.htype {
		display: block;
		width: 33.33%;
		height: 70px;
		float: left;
		cursor: pointer;
		text-align: center;
		line-height: 70px;
		color: #fff;
		font-size: 18px;
		letter-spacing: 2px;
		transition: all 0.6s;
	}

	.htype:hover {
		background-color: #b74231;
		transition: all 0.6s;
	}

	.header2 .on {
		background-color: #b74231;
	}
</style>

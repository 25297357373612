import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'
import router from './router'

import "./assets/iconfont.css";

import axios from 'axios'
Vue.prototype.$axios = axios


import {Carousel,CarouselItem} from 'element-ui'

Vue.use(VueRouter)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.config.productionTip = false

new Vue({
  el:"#app",
  render: h => h(App),
  router
})

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})
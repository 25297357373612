<template>

	<div class="fullPage">
		<div class="header">
			<div class="header-main">
				<a class="header-logo" href="http://cn.conspit.com/"></a>
				<div class="header-menu">
					<div class="header-item has2-header">
						<div class="header-span" @mouseenter="enterOne" @mouseleave="leaveOne">
							踏板
						</div>
					</div>

					<div class="header-item has2-header">
						<div class="header-span" @mouseenter="enterTwo" @mouseleave="leaveTwo">周边生态</div>
					</div>

					<div class="header-item has2-header">
						<div class="header-span" @mouseenter="enterThree" @mouseleave="leaveThree">支架座椅系列</div>
					</div>

					<!-- <div class="header-item">
						<a class="header-a">方向盘</a>
					</div> -->
					<div class="header-item has2-header">
						<div class="header-span" @mouseenter="enterFour" @mouseleave="leaveFour">座舱&环幕</div>
					</div>

					<div class="header-item">
						<a href="http://store.conspit.com/" class="header-a" style="color:#b74231;">定制套餐</a>
					</div>
					<div class="header-item">
						<a  class="header-a">合作伙伴</a>
					</div>
					<div class="header-item">
						<a href="http://cn.conspit.com/download.html" class="header-a">下载</a>
					</div>
				</div>
				<div class="header-search-btn">
					<a href="https://www.conspit.com">EN</a>
					<a class="islan">中文</a>
				</div>
			</div>
		</div>
		<!--2级菜单-->

		<div class="header-view-one" v-if="one" @mouseenter="enterOne" @mouseleave="leaveOne">
			<a href="http://cn.conspit.com/info/cgp.html">CGP 压力踏板</a>
			<a href="http://cn.conspit.com/info/cpp.html">CPP 液压踏板</a>
		</div>

		<div class="header-view-two" v-if="two" @mouseenter="enterTwo" @mouseleave="leaveTwo">
			<a href="http://cn.conspit.com/info/ss.html">H2 液压手刹</a>
			<a>序列档</a>
			<a>Conspit地毯</a>
			<a>Glow2 尾灯</a>
		</div>

		<div class="header-view-three" v-if="three" @mouseenter="enterThree" @mouseleave="leaveThree">
			<a href="http://cn.conspit.com/info/cfp.html">方程式支架</a>
			<a href="http://cn.conspit.com/info/gt.html">GT支架</a>
			<a>座椅</a>
		</div>

		<div class="header-view-four" v-if="four" @mouseenter="enterFour" @mouseleave="leaveFour">
			<a href="http://cn.conspit.com/info/zc.html">F1 Pro座舱</a>
			<a>F1 Lite座舱</a>
			<a href="http://cn.conspit.com/info/halo.html">Halo 270环幕</a>
		</div>
		<!--2级菜单 end-->
		
		<div style="width: 100%;height: 70px;"></div>
		<div class="tb_box">
			<div class="tb_box_main">
				<img class="tb_img" src="../assets/p1_img.png">
				<div class="tb-title">GT入门款</div>
				<div class="tb-title2">乐享其速</div>
				<div class="tb-main">
					Conspit Gt-Lite支架<br>
					Conspit CGP-V 2压感踏板<br>
					Fanatec CSL DD基座<br>
					Fanatec F1 V2碳纤维方向盘
				</div>
				<div class="tb-price">*￥13497起</div>
				<router-link :to="{path:'/p1'}">
					<span class="link">开始定制</span>
				</router-link>
			</div>
		</div>
		<div class="cc_box">
			<div class="cc_box_main">
				<img class="cc_img" src="../assets/p2_img.png">
				<div class="cc-title">GT进阶款</div>
				<div class="cc-title2">竟驾于此</div>
				<div class="cc-main">
					Conspit Gt-Pneumatic气压支架<br>
					Conspit CPP-V 3油压感踏板<br>
					Fanatec CSL DD基座<br>
					Fanatec F1 V2碳纤维方向盘
				</div>
				<div class="cc-price">*￥17397起</div>
				<router-link :to="{path:'/p2'}">
					<span class="link">开始定制</span>
				</router-link>
			</div>
		</div>
		
		<div class="tb_box">
			<div class="tb_box_main">
				<img class="tb_img" src="../assets/p3_img.png" style="height: 380px;">
				<div class="tb-title">方程式套装</div>
				<div class="tb-title2">硬核之选</div>
				<div class="tb-main">
					Conspit FP 方程式赛车气压支架<br>
					Conspit CPP-V 2油压感踏板<br>
					Fanatec CSL DD基座<br>
					Fanatec F1 V2碳纤维方向盘
				</div>
				<div class="tb-price">*￥16097起</div>
				<router-link :to="{path:'/p3'}">
					<span class="link">开始定制</span>
				</router-link>
			</div>
		</div>
		<div class="cc_box">
			<div class="cc_box_main">
				<img class="cc_img" src="../assets/p4_img.png" style="height: 289px;">
				<div class="cc-title">F1 座舱款</div>
				<div class="cc-title2">驶临其境</div>
				<div class="cc-main">
					Conspit F1座舱+配件<br>
					Conspit CGP-V 2X 压感踏板<br>
					Fanatec CSL DD基座<br>
					Fanatec F1 V2碳纤维方向盘
				</div>
				<div class="cc-price">*￥37947起</div>
				<router-link :to="{path:'/p4'}">
					<span class="link">开始定制</span>
				</router-link>
			</div>
		</div>

	</div>

</template>

<script>
	document.title = '定制套餐 - Conspit';
	export default {
		name: "Header",
		data() {
			return {
				one: false,
				two: false,
				three: false,
				four: false
			}
		},
		
		methods: {
			enterOne() {
				this.one = true
			},
			leaveOne() {
				this.one = false
			},
			enterTwo() {
				this.two = true
			},
			leaveTwo() {
				this.two = false
			},
			enterThree() {
				this.three = true
			},
			leaveThree() {
				this.three = false
			},
			enterFour() {
				this.four = true
			},
			leaveFour() {
				this.four = false
			},
			_isMobile() {
				let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
				return flag;
			}
		},
		mounted() {
			if (this._isMobile()) {
				console.log("手机端");
				location.href="https://cn.conspit.com/store?action=index"
			} else {
				console.log("pc端");
			}
		}
	};
</script>

<style>

	* {
		padding: 0;
		margin: 0;
		border: 0;
		font-family: "微软雅黑";
	}

	html,
	body {
		width: 100%;
	}
	
	.fullPage{
		
	}

	a {
		text-decoration: none;
	}

	.header {
		width: 100%;
		height: 70px;
		background-color: #fff;
		position: fixed;
		z-index: 999;
	}

	.header-main {
		width: 1200px;
		height: 70px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		background-color: #fff;
	}

	.header-logo {
		width: 160px;
		height: 70px;
		float: left;
		background-image: url(../assets/head_logo.png);
		background-size: 160px 25px;
		background-repeat: no-repeat;
		background-position: center;
	}

	.header-menu {
		width: 985px;
		display: flex;
		height: 30px;
		float: left;
		cursor: default;
	}

	.header-item {
		display: inline-block;
		height: 70px;
		margin-left: 50px;
		position: relative;
	}

	.header-span {
		display: inline-block;
		height: 70px;
		line-height: 70px;
		color: #303233;
		font-size: 20px;
		font-weight: bold;
		cursor: pointer;
	}

	.header-menu .header-item .header-a {
		display: inline-block;
		height: 70px;
		line-height: 70px;
		color: #303233;
		font-size: 20px;
		font-weight: bold;
		transition: all 0.4s;
		cursor: pointer;
	}

	.header-menu .header-item .header-a:hover {
		color: rgb(183, 66, 49);
		transition: all 0.4s;
	}

	.header-view,
	.header-view-one,
	.header-view-two,
	.header-view-three,
	.header-view-four {
		width: 110px;
		background-color: #f2f2f2;
		position: absolute;
		top: 70px;
		z-index: 99;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
		/* display: none; */
		cursor: pointer;
	}

	.header-view-one {
		left: calc((100% - 1200px)/2 + 210px);
	}

	.header-view-two {
		left: calc((100% - 1200px)/2 + 300px);
	}

	.header-view-three {
		left: calc((100% - 1200px)/2 + 430px);
	}

	.header-view-four {
		left: calc((100% - 1200px)/2 + 600px);
	}

	.header-view a,
	.header-view-one a,
	.header-view-two a,
	.header-view-three a,
	.header-view-four a {
		display: block;
		cursor: pointer;
		height: 44px;
		line-height: 44px;
		text-indent: 6px;
		color: #333;
		font-size: 14px;
		border-bottom: 1px solid #fff;
		transition: all 0.4s;
	}

	.header-view a:hover,
	.header-view-one a:hover,
	.header-view-two a:hover,
	.header-view-three a:hover,
	.header-view-four a:hover {
		color: rgb(183, 66, 49);
		transition: all 0.4s;
	}

	.header-search-btn {
		height: 24px;
		float: left;
		background-color: #007aff;
		font-size: 0;
		margin-top: 23px;
		border: 1px solid #b74231;
	}

	.header-search-btn a {
		display: inline-block;
		font-size: 10px;
		padding: 0 3px;
		height: 24px;
		line-height: 24px;
		background-color: #fff;
		color: #b74231;
	}

	.islan {
		background-color: #b74231 !important;
		color: #fff !important;
	}

	.header2 {
		width: 100%;
		height: 70px;
		background-color: rgba(49, 51, 59, 1);
		position: relative;
		left: 0;
		top: 0;
		z-index: 10;
		z-index: -1;
	}

	.header2_back {
		width: 100%;
		height: 70px;
		display: none;
	}

	.header2-main {
		width: 1200px;
		height: 70px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);
	}

	.header2-title {
		display: inline-block;
		height: 70px;
		line-height: 70px;
		font-size: 18px;
		color: #fff;
	}

	.header2-title2 {
		display: inline-block;
		height: 70px;
		line-height: 70px;
		font-size: 20px;
		letter-spacing: 2px;
		color: #fff;
	}

	.header2-title2 span {
		letter-spacing: 1px;
	}

	.header2-download {
		display: inline-block;
		width: 120px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		border-radius: 5px;
		background-color: #b74231;
		color: #fff;
		font-size: 16px;
		letter-spacing: 1px;
		float: right;
		margin: 18px 0;
		transition: all 0.3s;
	}

	.header2-download:hover {
		background-color: #da5946;
		transition: all 0.3s;
	}

	.header2-lab {
		width: 240px;
		height: 70px;
		float: left;
		background-repeat: no-repeat;
		/* background-size: auto 30px; */
		background-position: 0px center;
	}

	.header2-link {
		float: right;
		display: inline-block;
		height: 20px;
		line-height: 20px;
		font-size: 16px;
		margin: 25px 0;
		margin-right: 55px;
		color: #fff;
		letter-spacing: 2px;
	}

	.htype {
		display: block;
		width: 33.33%;
		height: 70px;
		float: left;
		cursor: pointer;
		text-align: center;
		line-height: 70px;
		color: #fff;
		font-size: 18px;
		letter-spacing: 2px;
		transition: all 0.6s;
	}

	.htype:hover {
		background-color: #b74231;
		transition: all 0.6s;
	}

	.header2 .on {
		background-color: #b74231;
	}
	
	
	.tb_box {
	    width: 100%;
	    height: 1000px;
	    background-color: #fff;
	    position: relative;
	}

	.tb_box_main {
	    height: 100%;
	    width: 1200px;
	    position: absolute;
	    left: 50%;
	    top: 0;
	    transform: translateX(-50%);
	}
	
	.tb_box .link {
	    width: 170px;
	    height: 50px;
	    line-height: 50px;
	    text-align: center;
	    color: #fff;
	    letter-spacing: 2px;
	    font-size: 18px;
	    background-color: #b74231;
	    position: absolute;
	    left: 0;
	    bottom: 0px;
	}
	
	.tb_img {
	    height: 630px;
	    position: absolute;
	    right: 30px;
		top: 50%;
		transform: translateY(-50%);
	}
	
	.tb-title{
		width: 200px;
		font-size: 30px;
		color: #000;
		font-weight: bold;
		position: absolute;
		left: 0;
		top: 160px;
	}
	.tb-title2{
		width: 200px;
		font-size: 24px;
		color: #000;
		position: absolute;
		left: 0;
		top: 210px;
	}
	
	.tb-main{
		width: 500px;
		line-height: 40px;
		font-size: 24px;
		color: #000;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	
	.tb-price{
		width: 170px;
		text-align: center;
		font-size: 24px;
		color: #000;
		position: absolute;
		left: 0;
		bottom: 70px;
	}
	
	
	
	.cc_box {
	    width: 100%;
	    height: 1000px;
	    background-color: #0d0f19;
	    position: relative;
	}
	
	.cc_box_main {
	    height: 100%;
	    width: 1200px;
	    position: absolute;
	    left: 50%;
	    top: 0;
	    transform: translateX(-50%);
	}
	
	.cc_box .link {
	    width: 166px;
		height: 46px;
		line-height: 46px;
		text-align: center;
		color: #fff;
		letter-spacing: 2px;
		font-size: 18px;
		border: 2px solid #b74231;
		position: absolute;
		left: 840px;
		bottom: 160px;
		transition: all 0.5s;
	}
	.cc_box .link:hover {
		background-color: #b74231;
		color: #fff;
		transition: all 0.5s;
	}
	
	.cc_img {
	    height: 589px;
	    position: absolute;
	    left: 30px;
		top: 50%;
		transform: translateY(-50%);
	}
	
	.cc-title{
		width: 200px;
		font-size: 30px;
		color: #fff;
		position: absolute;
		left: 840px;
		top: 160px;
		font-weight: bold;
	}
	.cc-title2{
		width: 200px;
		font-size: 24px;
		color: #fff;
		position: absolute;
		left: 840px;
		top: 210px;
	}
	
	.cc-main{
		width: 500px;
		line-height: 40px;
		font-size: 22px;
		color: #fff;
		position: absolute;
		left: 840px;
		top: 50%;
		transform: translateY(-50%);
	}
	
	.cc-price{
		width: 170px;
		text-align: center;
		font-size: 22px;
		color: #fff;
		position: absolute;
		left: 840px;
		bottom: 230px;
	}
</style>

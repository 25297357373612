<template>
	<div>
		<Header />
		<div class="header2">
			<div class="header2-main">
				<div class="header2-title2"><span>Conspit</span> 模拟器套装配置</div>
			</div>
		</div>
		<div class="main">
			<div class="main_title">
				<div style="display: flex; width: 54%; justify-content: space-between">
					<div class="main_left">F1座舱模拟器套装</div>
					<div class="title_middle">
						<a target="_blank" href="http://cn.conspit.com/info/zc.html" class="title_left">产品详情</a>
						<div v-if="showPrice == false" @click="handleShowPrice" class="title_right">完成配置</div>
						<div v-if="showPrice == true" @click="handleShowPrice" class="title_right">继续选配</div>
					</div>
				</div>
				<div style="font-size: 13px;display:flex;align-items:center;">
					<div class="price_btn" v-if="showBtn" @click="submit">
						<i class="iconfont icon-four-squares-full"></i>
						<div>生成报价表</div>
					</div>
					<div>当前套餐总价：<span style="font-size: 18px">{{ priceSum }}￥</span></div>
				</div>
			</div>
			<div class="main_body">
				<!-- 轮播图 -->
				<div class="main_left_">
					<el-carousel :autoplay="false" arrow="always" indicator-position="inside" :indicator-dots="true">
						<el-carousel-item>
							<div style="
                  width: 100%;
                  height: 100%;
                  background: #b8b8b8;
                  position: relative;
                ">
								<img src="../assets/p4_img.png" style="
                    width: 75%;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  " />
							</div>
						</el-carousel-item>
					</el-carousel>
					<div class="left_standard">
						<div>标配产品</div>
					</div>
					<div class="left_content">
						<div>Conspit F1座舱（小）</div>
						<div style="color: #959595">|</div>
						<div>Fanatec CSL DD＋Fanatec F1 V2碳纤维方向盘</div>
						<div style="color: #959595">|</div>
						<div>F1座舱定制碳纤维延长杆</div>
					</div>
					<div class="left_content" style="justify-content: flex-start">
						<div>F1座舱踏板固定底座（无电动）</div>
						<div style="color: #959595; margin: 0 20px">|</div>
						<div>CGP-V 2X 油压踏板（无底座）</div>
					</div>
				</div>
				<!-- 配置 -->
				<div class="configure" v-if="showPrice == false">
					
					<!-- 款式 -->
						<div class="item_one">
							<div class="item_title" @click="openKs">
								<div style="font-weight: 600">款式</div>
								<i v-if="showKs" style="font-size: 18px; width: 200px;text-align: right; height: 100%;" class="iconfont icon-xiangshang"></i>
								<i v-if="!showKs" style="font-size: 18px;width: 200px;text-align: right; height: 100%;" class="iconfont icon-xiangxia"></i>
							</div>
							<div class="item_content" v-if="showKs">
								<div class="item">
									<img style="width: 60px; height: 60px;" src="../assets/s1.png" />
									<i class="iconfont icon-Raidobox-xuanzhong" @click="toPath(1)"></i>
									<div style="margin-left: 10px; margin-top: 15px; width: 66%">
										<div style="font-weight: 600;font-size: 15px;margin-bottom: 4px;">GT-Lite支架</div>
										<div @click="ToUrl('http://cn.conspit.com/info/gt.html')" style="cursor: pointer;">
											<i class="iconfont icon-tishi"></i><span style="font-size: 13px">产品详情</span>
										</div>
									</div>
									<div class="item_right">
										<div style="width: 70px;">-20100￥</div>
									</div>
								</div>
								<div class="item">
									<img style="width: 60px; height: 60px;" src="../assets/s2.png" />
									<i class="iconfont icon-Raidobox-xuanzhong" @click="toPath(2)"></i>
									<div style="margin-left: 10px; margin-top: 15px; width: 66%">
										<div style="font-weight: 600;font-size: 15px;margin-bottom: 4px;">Gt-Pneumatic支架</div>
										<div @click="ToUrl('http://cn.conspit.com/info/gt.html')" style="cursor: pointer;">
											<i class="iconfont icon-tishi"></i><span style="font-size: 13px">产品详情</span>
										</div>
									</div>
									<div class="item_right">
										<div style="width: 70px;">-18500￥</div>
									</div>
								</div>
								<div class="item">
									<img style="width: 60px; height: 60px;" src="../assets/s3.png" />
									<i class="iconfont icon-Raidobox-xuanzhong" @click="toPath(3)"></i>
									<div style="margin-left: 10px; margin-top: 15px; width: 66%">
										<div style="font-weight: 600;font-size: 15px;margin-bottom: 4px;">FP方程式支架</div>
										<div @click="ToUrl('https://cn.conspit.com/info/cfp.html')" style="cursor: pointer;">
											<i class="iconfont icon-tishi"></i><span style="font-size: 13px">产品详情</span>
										</div>
									</div>
									<div class="item_right">
										<div style="width: 70px;">-18000￥</div>
									</div>
								</div>
								<div class="item">
									<img style="width: 60px; height: 60px;" src="../assets/s4.png" />
									<i class="iconfont icon-Raidobox-xuanzhong" style="color: #a71717"></i>
									<div style="margin-left: 10px; margin-top: 15px; width: 66%">
										<div style="font-weight: 600;font-size: 15px;margin-bottom: 4px;">FC方程式座舱</div>
										<div @click="ToUrl('http://cn.conspit.com/info/zc.html')" style="cursor: pointer;">
											<i class="iconfont icon-tishi"></i><span style="font-size: 13px">产品详情</span>
										</div>
									</div>
									<div class="item_right">
										<div style="width: 70px;">+0￥</div>
									</div>
								</div>
							</div>
						</div>
					
					
					<!-- 颜色 -->
					<div class="item_one">
						<div class="item_title" @click="openColor">
							<div style="font-weight: 600">颜色</div>
							<i v-if="showOpen" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangshang"></i>
							<i v-if="!showOpen" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangxia"></i>
						</div>
						<div class="item_content" v-if="showOpen">
							<div class="content_title">
								<div :class="index == i ? 'title_one activeColor' : 'title_one'"
									@click="chooseColor(index)" v-for="(item, index) in colorList" :key="index">
									{{ item.content }}
								</div>
							</div>
							<div class="default">标准颜色</div>
							<div v-if="i == 0" style="width: 100%; display: flex">
								<div class="color_box">
									<div v-for="(item, index) in pedalColor" :key="index" class="color_"
										@click="changeChooseSecond(index)" @mouseenter="showColorTip(index)"
										@mouseleave="hiddenColorTip">
										<div v-if="tipIndex == index">
											<div class="color_tip" style="text-align: center">
												{{ item.name }}
											</div>
											<div class="triangle"></div>
										</div>
										<div>
											<div class="color_item" :style="item.style" v-if="pedalColorIndex != index">
											</div>
											<div class="color_item_big" :style="item.style"
												v-if="pedalColorIndex == index">
												<div class="circular" style="z-index: 999">
													<img class="true" src="../assets/true.png" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div style="width: 30%; text-align: right; line-height: 70px">
									+0￥
								</div>
							</div>
							<div v-if="i == 1" style="width: 100%; display: flex">
								<div class="color_box">
									<div v-for="(item, index) in chairColor" :key="index" class="color_"
										@click="changeChoose(index)" @mouseenter="showColorTip(index)"
										@mouseleave="hiddenColorTip">
										<div v-if="tipIndex == index">
											<div class="color_tip">{{ item.name }}</div>
											<div class="triangle"></div>
										</div>
										<div>
											<div :class="item.style" v-if="colorIndex != index">
												<img style="width: 28px; height: 28px; margin: 20px 16px"
													v-if="index == 1" src="../assets/paint.png" />
											</div>
											<div :class="item.enlargeStyle" v-if="colorIndex == index">
												<img style="width: 28px; height: 28px; margin: 20px 18px"
													v-if="index == 1" src="../assets/paint.png" />
												<div class="circular" style="z-index: 999">
													<img class="true" src="../assets/true.png" />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div style="width: 30%; text-align: right; line-height: 70px">
									+{{ defaultPrice }}￥
								</div>
							</div>
						</div>
					</div>
					<!-- 踏板 -->
					<div class="item_one">
						<div class="item_title" @click="openPedal">
							<div style="font-weight: 600">踏板选择及升级</div>
							<i v-if="showPedal" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangshang"></i>
							<i v-if="!showPedal" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangxia"></i>
						</div>
						<div class="item_content" v-if="showPedal">
							<div class="item" v-for="(item, index) in item" :key="index">
								<img style="width: 70px; height: 70px" :src="item.url" />
								<i v-if="index != c1" class="iconfont icon-Raidobox-xuanzhong"
									@click="select(index)"></i>
								<i v-if="index == c1" class="iconfont icon-Raidobox-xuanzhong" style="color: #a71717"
									@click="select(index)"></i>
								<div style="margin-left: 10px; margin-top: 15px; width: 58%">
									<div style="
                      font-weight: 600;
                      font-size: 15px;
                      margin-bottom: 4px;
                    ">
										{{ item.name }}
									</div>
									<div @click="ToUrl(item.detailUrl)" style="cursor: pointer">
										<i class="iconfont icon-tishi"></i><span style="font-size: 13px">产品详情</span>
									</div>
								</div>
								<div class="item_right">
									<div>+{{ item.price }}￥</div>
									<div>原价：{{ item.priceBefore }}￥</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 座舱-->
					<div class="item_one">
						<div class="item_title" @click="openHandbrake">
							<div style="font-weight: 600">座舱选择及升级</div>
							<i v-if="showHandbrake" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangshang"></i>
							<i v-if="!showHandbrake" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangxia"></i>
						</div>
						<div class="item_content" v-if="showHandbrake">
							<div class="item" v-for="(item, index) in handBrake" :key="index">
								<img v-if="index == 0" style="width: 70px; height: 50px" :src="item.url" />
								<img v-if="index == 1" style="width: 70px; height: 70px" :src="item.url" />
								<i v-if="index != brakeIndex" class="iconfont icon-Raidobox-xuanzhong"
									@click="selectHandbrake(index)"></i>
								<i v-if="index == brakeIndex" class="iconfont icon-Raidobox-xuanzhong"
									style="color: #a71717" @click="selectHandbrake(index)"></i>
								<div style="margin-left: 10px; margin-top: 15px; width: 55%">
									<div style="
                      font-weight: 600;
                      font-size: 15px;
                      margin-bottom: 4px;
                    ">
										{{ item.name }}
									</div>
									<div @click="ToUrl('http://cn.conspit.com/info/ss.html')" style="cursor: pointer">
										<i class="iconfont icon-tishi"></i><span
											style="font-size: 13px">{{ item.size }}</span>
									</div>
								</div>
								<div class="item_right" style="width: 55%">
									<div>+{{ item.price }}￥</div>
									<div>原价：{{ item.priceBefore }}￥</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 座舱配件 -->
					<div class="item_one">
						<div class="item_title" @click="openCockpit">
							<div style="font-weight: 600">座舱配件选择（可多选）</div>
							<i v-if="showCockpit" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangshang"></i>
							<i v-if="!showCockpit" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangxia"></i>
						</div>
						<div class="item_content" v-if="showCockpit">
							<div style="
                  display: flex;
                  width: 100%;
                  height: 30px;
                  line-height: 18px;
                  padding-top: 10px;
                " v-for="(item, index) in cockpit" :key="index">
								<input type="checkbox" style="margin-right: 15px" :checked="item.check"
									@click="changeCockpit(index)" />
								<div style="flex: 80%">
									<div style="font-size: 15px">{{ item.name }}</div>
								</div>
								<div style="flex: 20%; text-align: right; font-size: 15px">
									+{{ item.price }}￥
								</div>
							</div>
							<div style="
                  display: flex;
                  width: 100%;
                  height: 30px;
                  line-height: 20px;
                  padding-top: 10px;
                " @click="cancelCockpit">
								<input type="checkbox" style="margin-right: 15px" :checked="checkparts" />
								<div style="font-size: 15px">暂不选择</div>
								<div style="flex: 20%; text-align: right; font-size: 15px">
									+0￥
								</div>
							</div>
						</div>
					</div>
					<!-- 基座 -->
					<div class="item_one">
						<div class="item_title" @click="openPedestal">
							<div style="font-weight: 600">基座选择及升级</div>
							<i v-if="showPedestal" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangshang"></i>
							<i v-if="!showPedestal" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangxia"></i>
						</div>
						<div class="item_content" v-if="showPedestal">
							<div class="item" v-for="(item, index) in pedestal" :key="index">
								<img v-if="index == 0" style="width: 70px; height: 70px" :src="item.url" />
								<img v-if="index != 0" style="width: 60px; height: 70px; margin: 0 8px 0 5px"
									:src="item.url" />
								<i v-if="index != c2" class="iconfont icon-Raidobox-xuanzhong"
									@click="selectPedestal(index)"></i>
								<i v-if="index == c2" class="iconfont icon-Raidobox-xuanzhong" style="color: #a71717"
									@click="selectPedestal(index)"></i>
								<div style="margin-left: 10px; width: 56%">
									<div style="font-weight: 600; font-size: 15px">
										{{ item.name }}
									</div>
								</div>
								<div class="item_right">
									<div>+{{ item.price }}￥</div>
									<div>原价：{{ item.priceBefore }}￥</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 方向盘 -->
					<div class="item_one">
						<div class="item_title" @click="openDirection">
							<div style="font-weight: 600">方向盘选择及升级</div>
							<i v-if="showDirection" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangshang"></i>
							<i v-if="!showDirection" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangxia"></i>
						</div>
						<div class="item_content" v-if="showDirection">
							<div class="item" v-for="(item, index) in directionwheel" :key="index">
								<img v-if="index != 0" style="width: 70px; height: 70px" :src="item.url" />
								<img v-if="index == 0" style="width: 70px; height: 45px" :src="item.url" />
								<i v-if="index != c3" class="iconfont icon-Raidobox-xuanzhong"
									@click="selectDirection(index)"></i>
								<i v-if="index == c3" class="iconfont icon-Raidobox-xuanzhong" style="color: #a71717"
									@click="selectDirection(index)"></i>
								<div style="margin-left: 10px; width: 58%">
									<div style="font-weight: 600; font-size: 15px">
										{{ item.name }}
									</div>
								</div>
								<div class="item_right">
									<div v-if="index != 2 && index != 3 && index != 4">
										+{{ item.price }}￥
									</div>
									<div v-if="index == 2 || index == 3 || index == 4">
										{{ item.price }}￥
									</div>
									<div>原价：{{ item.priceBefore }}￥</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 显示器 -->
					<div class="item_one">
						<div class="item_title" @click="openScreen">
							<div style="font-weight: 600">显示器配置选择</div>
							<i v-if="showScreen" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangshang"></i>
							<i v-if="!showScreen" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangxia"></i>
						</div>
						<div class="item_content" v-if="showScreen">
							<div class="item" v-for="(item, index) in screen" :key="index">
								<img v-if="index != 2" style="width: 70px; height: 70px" :src="item.url" />
								<img v-if="index == 2" style="width: 70px; height: 40px" :src="item.url" />
								<i v-if="index != c4" class="iconfont icon-Raidobox-xuanzhong"
									@click="selectScreen(index)"></i>
								<i v-if="index == c4" class="iconfont icon-Raidobox-xuanzhong" style="color: #a71717"
									@click="selectScreen(index)"></i>
								<div v-if="index != 2 && index != 3"
									style="margin-left: 10px; margin-top: 15px; width: 63%">
									<div style="
										font-weight: 600;
										font-size: 15px;
										margin-bottom: 4px;
										">
										{{ item.name }}
									</div>
									<div @click="ToUrl(item.detailUrl)" style="cursor: pointer;">
										<i class="iconfont icon-tishi"></i><span style="font-size: 13px">产品详情</span>
									</div>
								</div>
								<div v-if="index == 2 || index == 3" style="margin-left: 10px; width: 64%">
									<div style="font-weight: 600; font-size: 15px">
										{{ item.name }}
									</div>
								</div>
								<div class="item_right">
									<div>+{{ item.price }}￥</div>
								</div>
							</div>
							<div class="item" @click="cancelSelect">
								<i v-if="!cancel" class="iconfont icon-Raidobox-xuanzhong"
									style="margin-left: 92px"></i>
								<i v-if="cancel" class="iconfont icon-Raidobox-xuanzhong"
									style="margin-left: 92px; color: #a71717"></i>
								<div style="margin-left: 10px; margin-top: 15px">
									<div style="
                      font-weight: 600;
                      font-size: 15px;
                      margin-bottom: 4px;
                      margin-top: -12px;
                    ">
										暂不选择
									</div>
								</div>
								<div class="item_right" style="width: 63%">
									<div>+0￥</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 电脑配置 -->
					<div class="item_one">
						<div class="item_title" @click="openConfigure">
							<div style="font-weight: 600">电脑配置选择</div>
							<i v-if="showConfigure" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangshang"></i>
							<i v-if="!showConfigure" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangxia"></i>
						</div>
						<div class="item_content" v-if="showConfigure">
							<div style="
                  display: flex;
                  width: 100%;
                  height: 60px;
                  line-height: 20px;
                  padding-top: 10px;
                " @click="chooseConfigure(index)" v-for="(item, index) in configure" :key="index">
								<div style="position: relative; margin-right: 25px">
									<i class="iconfont icon-fangkuang"
										style="position: absolute; top: 0; font-size: 19px"></i>
									<div v-if="index == c" style="
                      position: absolute;
                      top: 0;
                      left: 3px;
                      color: #a71717;
                      font-size: 17px;
                    ">
										✓
									</div>
								</div>
								<div style="flex: 80%; margin-bottom: 5px">
									<div style="font-size: 15px">{{ item.name }}</div>
									<div style="font-size: 13px">{{ item.small }}</div>
								</div>
								<div style="flex: 20%; text-align: right; font-size: 15px">
									+{{ item.price }}￥
								</div>
							</div>
							<div style="
                  display: flex;
                  width: 100%;
                  height: 36px;
                  line-height: 20px;
                  padding-top: 10px;
                " @click="cancelConfigure">
								<div style="position: relative; margin-right: 25px">
									<i class="iconfont icon-fangkuang"
										style="position: absolute; top: 0; font-size: 19px"></i>
									<div v-if="showG" style="
                      position: absolute;
                      top: 0;
                      left: 3px;
                      color: #a71717;
                      font-size: 17px;
                    ">
										✓
									</div>
								</div>
								<div style="font-size: 15px">暂不选择</div>
								<div style="flex: 20%; text-align: right; font-size: 15px">
									+0￥
								</div>
							</div>
						</div>
					</div>
					<!-- 配件 -->
					<div class="item_one">
						<div class="item_title" @click="openServe">
							<div style="font-weight: 600">配件及服务（可多选）</div>
							<i v-if="showServe" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangshang"></i>
							<i v-if="!showServe" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangxia"></i>
						</div>
						<div class="item_content" v-if="showServe">
							<div style="
                  display: flex;
                  width: 100%;
                  height: 40px;
                  line-height: 18px;
                  padding-top: 10px;
                " v-for="(item, index) in serve" :key="index">
								<input type="checkbox" style="margin-right: 15px" :checked="item.check"
									@click="changeCheck(index)" />
								<div style="flex: 80%">
									<div style="font-size: 15px">{{ item.name }}</div>
								</div>
								<div style="flex: 20%; text-align: right; font-size: 15px" v-if=" item.name == '差旅膳宿' ">
									实报实销
								</div>
								<div style="flex: 20%; text-align: right; font-size: 15px" v-else-if=" item.name == '运费' ">
									到付
								</div>
								<div style="flex: 20%; text-align: right; font-size: 15px" v-else>
									+{{ item.price }}￥
								</div>
							</div>
							<div style="
                  display: flex;
                  width: 100%;
                  height: 40px;
                  line-height: 20px;
                  padding-top: 10px;
                " @click="cancelServe">
								<input type="checkbox" style="margin-right: 15px" :checked="isCheck" />
								<div style="font-size: 15px">暂不选择</div>
								<div style="flex: 20%; text-align: right; font-size: 15px">
									+0￥
								</div>
							</div>
						</div>
					</div>
					<!-- 游戏选择 -->
					<div class="item_one">
						<div class="item_title" @click="openGame">
							<div style="font-weight: 600">游戏选择（可多选）</div>
							<i v-if="showGame" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangshang"></i>
							<i v-if="!showGame" style="
                  font-size: 18px;
                  width: 200px;
                  text-align: right;
                  height: 100%;
                " class="iconfont icon-xiangxia"></i>
						</div>
						<div class="item_content" v-if="showGame">
							<div style="
                  display: flex;
                  width: 100%;
                  height: 30px;
                  line-height: 18px;
                  padding-top: 10px;
                " v-for="(item, index) in game" :key="index">
								<input type="checkbox" style="margin-right: 15px" :checked="item.check"
									@click="changeGame(index)" />
								<div style="flex: 80%">
									<div style="font-size: 15px">{{ item.name }}</div>
								</div>
								<div style="flex: 20%; text-align: right; font-size: 15px">
									+{{ item.price }}￥
								</div>
							</div>
							<div style="
                  display: flex;
                  width: 100%;
                  height: 30px;
                  line-height: 20px;
                  padding-top: 10px;
                " @click="cancelGame">
								<input type="checkbox" style="margin-right: 15px" :checked="isChecked" />
								<div style="font-size: 15px">暂不选择</div>
								<div style="flex: 20%; text-align: right; font-size: 15px">
									+0￥
								</div>
							</div>
						</div>
					</div>
					<div v-if="showPrice == false" @click="handleShowPrice" class="title_right" style="width: 140px; height: 35px; margin-bottom: 130px; border-radius: 4px;margin-top: 40px;margin-left: 350px;">完成配置</div>
				</div>
				<div class="configure" v-if="showPrice">
					<div class="configure_title">
						<div>配置</div>
						<div>说明</div>
						<div>价格</div>
					</div>
					<div>
						<div class="price_item" v-for="(config, configIndex) in finalMap" :key="configIndex">
							<div style="font-weight: 600; font-size: 17px; margin-bottom: 10px">
								{{ configIndex }}
							</div>
							<div v-if="config.length != 0">
								<div class="option_item" v-for="(item, index) in config" :key="index">
									<div style="font-size: 15px; width: 300px">
										{{ item.name }}
									</div>
									<div style="font-size: 15px; width: 300px">
										{{ item.description }}
									</div>
									<div v-if="item.name == '差旅膳宿'" style="font-size:15px;width:100px;text-align:right;">
										实报实销</div>
									<div v-else-if="item.name == '运费'" style="font-size:15px;width:100px;text-align:right;">
										到付</div>
									<template v-else>
										<div v-if="item.price >=0" style="font-size:15px;width:100px;text-align:right;">
											+{{item.price}}</div>
										<div v-if="item.price<0" style="font-size:15px;width:100px;text-align:right;">
											{{item.price}}</div>
									</template>
								</div>
							</div>
							<div v-if="config.length == 0">暂无选择</div>
						</div>
					</div>
					<div v-if="showPrice == true" @click="handleShowPrice" class="title_right" style="width: 140px; height: 35px; margin-bottom: 130px; border-radius: 4px;margin-top: 40px;margin-left: 350px;">继续选配</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from "../components/Header.vue";
	export default {
		name: "p4",
		components: {
			Header,
		},
		data() {
			return {
				showPrice: false,
				finalMap: {
					默认配置: [{
							name: "Conspit F1座舱（小）",
							description: "",
							price: 22999,
						},
						{
							name: "Fanatec CSL DD＋Fanatec F1 V2碳纤维方向盘",
							description: "",
							price: 7599,
						},
						{
							name: "F1座舱定制碳纤维延长杆",
							description: "",
							price: 3000,
						},
						{
							name: "F1座舱踏板固定底座（无电动）",
							description: "",
							price: 2000,
						},
						{
							name: "CGP-V 2X 油压踏板（无底座）",
							description: "",
							price: 2349,
						},
					],
					颜色: [{
							name: "踏板颜色",
							description: "晨曦金",
							price: 0,
						},
						{
							name: "座舱颜色",
							description: "红黑",
							price: 0,
						},
					],
					踏板选配: [{
						name: "标配CGP压感震动双踏板（无搁脚底板）",
						description: "",
						price: 0,
					}, ],
					座舱选择及升级: [{
						name: "标配F1座舱（小）",
						description: "尺寸2339×798×933mm",
						price: 0,
					}, ],
					座舱配件选择: [],
					基座配置: [{
						name: "标配Fanatec CSL DD",
						description: "",
						price: 0,
					}, ],
					方向盘配置: [{
						name: "标配Fanatec F1 V2碳纤维方向盘",
						description: "",
						price: 0,
					}, ],
					显示器配置: [],
					电脑配置: [],
					配件及服务: [],
					游戏选择: [],
				},
				i: 0,
				c1: 0,
				c2: 0,
				c3: 0,
				c4: -1,
				c: -1,
				colorList: [{
					content: "踏板颜色"
				}, {
					content: "座舱颜色"
				}],
				showOpen: true, // 颜色展示与隐藏
				colorIndex: 0,
				tipIndex: -1,
				defaultPrice: 0,
				chairColor: [{
						name: "红黑",
						price: 0,
						style: "color_item_",
						enlargeStyle: "color__item__big",
					},
					{
						name: "定制颜色",
						price: 2000,
						style: "customized",
						enlargeStyle: "customized_big",
					},
				],
				pedalColor: [{
						name: "晨曦金",
						style: "background:#ae823b;margin-right:10px;"
					},
					{
						name: "烈艳红",
						style: "background:#a71717;"
					},
				],
				pedalColorIndex: 0,
				showPedal: true, // 踏板展示与隐藏
				item: [{
						url: require("../assets/26.png"),
						name: "标配CGP压感震动双踏板（无搁脚底板）",
						price: 0,
						priceBefore: 2349,
					},
					{
						url: require("../assets/27.png"),
						name: "升级CPP油压震动双踏板（无搁脚底板）",
						price: 500,
						priceBefore: 2849,
					},
				],
				showHandbrake: true, // 座舱展示与隐藏
				brakeIndex: 0,
				handBrake: [{
						url: require("../assets/28.png"),
						name: "标配F1座舱（小） ",
						size: "尺寸2339×798×933mm",
						price: 0,
						priceBefore: 22999,
					},
					{
						url: require("../assets/29.png"),
						name: "升级F1座舱（大） ",
						size: "尺寸2421×1396×1050mm",
						price: 5000,
						priceBefore: 27999,
					},
				],
				showCockpit: true, // 座舱展示与隐藏
				cockpit: [{
						name: "电动踏板固定底座（推荐）",
						price: 1000,
						check: false,
					},
					{
						name: "赛车涂装",
						price: 1500,
						check: false,
					},
				],
				showPedestal: true, // 基座的展示与隐藏
				pedestal: [{
						url: require("../assets/11.png"),
						name: "标配Fanatec CSL DD",
						price: 0,
						priceBefore: 4000,
					},
					{
						url: require("../assets/13.png"),
						name: "升级Fanatec Podium DD1",
						price: 8000,
						priceBefore: 12000,
					},
					{
						url: require("../assets/13.png"),
						name: "升级Fanatec Podium DD2",
						price: 9500,
						priceBefore: 13500,
					},
				],
				showDirection: true, // 方向盘展示与隐藏
				directionwheel: [{
					url: require("../assets/14.png"),
					name: "标配Fanatec F1 V2碳纤维方向盘",
					price: 0,
					priceBefore: 3599,
				}, ],
				showScreen: false, // 显示器的显示与隐藏
				screen: [{
						url: require("../assets/19.png"),
						name: "Halo 270（2m直径环幕，占地2×1.85米）",
						price: 49999,
						detailUrl: 'https://cn.conspit.com/info/halo.html'
					},
					{
						url: require("../assets/20.png"),
						name: "Halo 270（3m直径环幕，占地3×2.85米）",
						price: 59999,
						detailUrl: 'https://cn.conspit.com/info/halo.html'
					},
					{
						url: require("../assets/18.png"),
						name: "三星49寸带鱼屏显示器＋显示器支架",
						price: 5999,
					},
					{
						url: require("../assets/30.png"),
						name: "65寸240hz显示器＋显示器支架",
						price: 20000,
					},
				],
				twoOption: false,
				cancel: false,
				cancelselect: false,
				showConfigure: false, // 配置展示与隐藏
				checkedList: [], // 电脑配置
				configure: [{
						name: "3050显卡电脑",
						small: "（i5 11400f，b560m，16g内存，512g固态）适合单屏显示器",
						price: 5800,
					},
					{
						name: "3070ti显卡电脑",
						small: "（i7 12700f，b660m，16g内存，512g固态）适合三屏幕显示器",
						price: 9800,
					},
					{
						name: "3080ti显卡电脑",
						small: "（i9 12900f，z690p，16g内存，512g固态）适合conspit环幕套装",
						price: 16000,
					},
				],
				showG: false,
				showServe: false, // 配件
				serve: [{
						name: "无线键鼠（罗技K400 Plus）",
						price: 150,
						check: false
					},
					{
						name: "音箱（漫步者）",
						price: 150,
						check: false
					},
					{
						name: "江浙沪上门服务",
						price: 1500,
						check: false
					},
					{
						name: "其余地区上门服务",
						price: 2000,
						check: false
					},
					{
						name: "差旅膳宿",
						price: 0,
						check: false
					},
					{
						name: "运费",
						price: 0,
						check: false
					},
				],
				isCheck: false,
				showGame: false,
				game: [{
						name: "神力科莎",
						price: 162,
						check: false
					},
					{
						name: "神力科莎竞技",
						price: 138,
						check: false
					},
				],
				isChecked: false,
				priceSum: 0,
				checkparts: false,
				showBtn: false,
				showKs: true,
			};
		},
		methods: {
			openKs(){
				if (this.showKs == true) {
					this.showKs = false
				} else {
					this.showKs = true
				}
			},
			// 完成配置
			handleShowPrice() {
				this.showPrice = !this.showPrice
				if (this.showPrice == true) {
					this.showBtn = true
				} else {
					this.showBtn = false
				}
			},
			chooseColor(index) {
				this.i = index;
			},
			openColor() {
				if (this.showOpen == true) {
					this.showOpen = false;
				} else {
					this.showOpen = true;
				}
			},
			// 座舱选择颜色
			changeChoose(index) {
				this.priceSum = this.priceSum - this.chairColor[this.colorIndex].price;
				this.colorIndex = index;
				this.defaultPrice = this.chairColor[this.colorIndex].price;
				this.priceSum = this.priceSum + this.defaultPrice;
				for (let i = 0; i < this.finalMap.颜色.length; i++) {
					if (this.finalMap.颜色[i].name === '座舱颜色') {
						this.finalMap.颜色.splice(i, 1);
						break;
					}
				}
				this.finalMap.颜色.push({
					name: '支架颜色',
					description: this.chairColor[this.colorIndex].name,
					price: this.defaultPrice,
				})
			},
			showColorTip(index) {
				this.tipIndex = index;
			},
			hiddenColorTip() {
				this.tipIndex = -1;
			},
			// 踏板颜色
			changeChooseSecond(index) {
				this.pedalColorIndex = index;
				for (let i = 0; i < this.finalMap.颜色.length; i++) {
					if (this.finalMap.颜色[i].name === '踏板颜色') {
						this.finalMap.颜色.splice(i, 1);
						break;
					}
				}
				this.finalMap.颜色.push({
					name: '踏板颜色',
					description: this.pedalColor[this.pedalColorIndex].name,
					price: 700,
				})
			},
			openPedal() {
				if (this.showPedal == true) {
					this.showPedal = false;
				} else {
					this.showPedal = true;
				}
			},
			// 打开座舱
			openHandbrake() {
				if (this.showHandbrake == true) {
					this.showHandbrake = false;
				} else {
					this.showHandbrake = true;
				}
			},
			openCockpit() {
				if (this.showCockpit == true) {
					this.showCockpit = false;
				} else {
					this.showCockpit = true;
				}
			},
			openPedestal() {
				if (this.showPedestal == true) {
					this.showPedestal = false;
				} else {
					this.showPedestal = true;
				}
			},
			openDirection() {
				if (this.showDirection == true) {
					this.showDirection = false;
				} else {
					this.showDirection = true;
				}
			},
			openScreen() {
				if (this.showScreen == true) {
					this.showScreen = false;
				} else {
					this.showScreen = true;
				}
			},
			// 踏板选择
			select(i) {
				if (this.priceSum > 0) {
					this.priceSum = this.priceSum - this.item[this.c1].price;
				}
				this.finalMap.踏板选配 = []
				this.c1 = i;
				this.finalMap.踏板选配.push({
					name: this.item[this.c1].name,
					description: '',
					price: this.item[this.c1].price,
				})
				this.priceSum = this.priceSum + this.item[i].price;
			},
			// 选择座舱
			selectHandbrake(i) {
				if (this.priceSum > 0 && this.brakeIndex != -1) {
					this.priceSum =
						this.priceSum -
						this.handBrake[this.brakeIndex].price;
				}
				this.finalMap.座舱选择及升级 = []
				this.brakeIndex = i;
				this.finalMap.座舱选择及升级.push({
					name: this.handBrake[this.brakeIndex].name,
					description: this.handBrake[this.brakeIndex].size,
					price: this.handBrake[this.brakeIndex].price,
				})
				this.twoOption = false;
				this.priceSum =
					this.priceSum +
					this.handBrake[this.brakeIndex].price
			},
			// 选择基座
			selectPedestal(i) {
				if (this.priceSum > 0) {
					this.priceSum =
						this.priceSum - this.pedestal[this.c2].price;
				}
				this.finalMap.基座配置 = []
				this.c2 = i;
				this.finalMap.基座配置.push({
					name: this.pedestal[this.c2].name,
					description: '',
					price: this.pedestal[this.c2].price
				})
				this.priceSum =
					this.priceSum + this.pedestal[i].price;
			},
			// 选择方向盘
			selectDirection(i) {
				if (this.priceSum > 0) {
					this.priceSum =
						this.priceSum -
						this.directionwheel[this.c3].price;
				}
				this.finalMap.方向盘配置 = []
				this.c3 = i;
				this.finalMap.方向盘配置.push({
					name: this.directionwheel[this.c3].name,
					description: '',
					price: this.directionwheel[this.c3].price
				})
				this.priceSum =
					this.priceSum +
					this.directionwheel[i].price;
			},
			// 选择显示器
			selectScreen(i) {
				if (this.priceSum > 0 && this.c4 != -1) {
					this.priceSum =
						this.priceSum -
						this.screen[this.c4].price;
				}
				this.finalMap.显示器配置 = []
				this.c4 = i;
				this.finalMap.显示器配置.push({
					name: this.screen[this.c4].name,
					description: '',
					price: this.screen[this.c4].price
				})
				this.cancel = false;
				this.priceSum =
					this.priceSum + this.screen[i].price;
			},
			// 选择屏幕的暂不选择
			cancelSelect() {
				this.finalMap.显示器配置 = []
				this.cancel = true;
				this.priceSum =
					this.priceSum -
					this.screen[this.c4].price;
				this.c4 = -1;
			},
			openConfigure() {
				if (this.showConfigure == true) {
					this.showConfigure = false;
				} else {
					this.showConfigure = true;
				}
			},
			chooseConfigure(i) {
				if (this.priceSum > 0 && this.c != -1) {
					this.priceSum = this.priceSum - this.configure[this.c].price;
				}
				this.finalMap.电脑配置 = []
				this.c = i;
				this.finalMap.电脑配置.push({
					name: this.configure[this.c].name,
					description: this.configure[this.c].small,
					price: this.configure[this.c].price
				})
				this.showG = false;
				this.priceSum = this.priceSum + this.configure[i].price;
			},
			// 选择暂不选择配置
			cancelConfigure() {
				this.finalMap.电脑配置 = []
				this.showG = true;
				this.priceSum = this.priceSum - this.configure[this.c].price;
				this.c = -1;
			},
			openServe() {
				if (this.showServe == true) {
					this.showServe = false;
				} else {
					this.showServe = true;
				}
			},
			// 选择配件与服务
			changeCheck(i) {
				if (this.serve[i].check == true) {
					this.serve[i].check = false;
					for (var j = 0; j < this.finalMap.配件及服务.length; j++) {
						if (this.finalMap.配件及服务[j].name === this.serve[i].name) {
							this.finalMap.配件及服务.splice(j, 1);
							break;
						}
					}
					this.priceSum = this.priceSum - this.serve[i].price;
				} else {
					this.serve[i].check = true;
					this.isCheck = false;
					this.priceSum = this.priceSum + this.serve[i].price;
					this.finalMap.配件及服务.push({
						name: this.serve[i].name,
						description: '',
						price: this.serve[i].price
					})
				}
			},
			// 选择座舱配件
			changeCockpit(index) {
				if (this.cockpit[index].check == true) {
					this.cockpit[index].check = false;
					for (var j = 0; j < this.finalMap.座舱配件选择.length; j++) {
						if (this.finalMap.座舱配件选择[j].name === this.cockpit[index].name) {
							this.finalMap.座舱配件选择.splice(j, 1);
							break;
						}
					}
					this.priceSum = this.priceSum - this.cockpit[index].price;
				} else {
					this.cockpit[index].check = true;
					this.finalMap.座舱配件选择.push({
						name: this.cockpit[index].name,
						description: '',
						price: this.cockpit[index].price
					})
					this.checkparts = false;
					this.priceSum = this.priceSum + this.cockpit[index].price;
				}
			},
			// 暂不选择座舱配件
			cancelCockpit() {
				this.finalMap.座舱配件选择 = []
				this.checkparts = true;
				for (var i = 0; i < this.cockpit.length; i++) {
					if (this.cockpit[i].check == true) {
						this.cockpit[i].check = false;
						this.priceSum = this.priceSum - this.cockpit[i].price;
					}
				}
			},
			// 选择游戏
			changeGame(i) {
				if (this.game[i].check == true) {
					this.game[i].check = false;
					for (var j = 0; j < this.finalMap.游戏选择.length; j++) {
						if (this.finalMap.游戏选择[j].name === this.game[i].name) {
							this.finalMap.游戏选择.splice(j, 1);
							break;
						}
					}
					this.priceSum = this.priceSum - this.game[i].price;
				} else {
					this.game[i].check = true;
					this.finalMap.游戏选择.push({
						name: this.game[i].name,
						description: '',
						price: this.game[i].price
					})
					this.isChecked = false;
					this.priceSum = this.priceSum + this.game[i].price;
				}
			},
			// 暂不选择游戏
			cancelGame() {
				this.finalMap.游戏选择 = []
				this.isChecked = true;
				for (var i = 0; i < this.game.length; i++) {
					if (this.game[i].check == true) {
						this.game[i].check = false;
						this.priceSum = this.priceSum - this.game[i].price;
					}
				}
			},
			// 选择暂不选择服务
			cancelServe() {
				this.finalMap.配件及服务 = []
				this.isCheck = true;
				for (var i = 0; i < this.serve.length; i++) {
					if (this.serve[i].check == true) {
						this.serve[i].check = false;
						this.priceSum = this.priceSum - this.serve[i].price;
					}
				}
			},
			openGame() {
				if (this.showGame == true) {
					this.showGame = false;
				} else {
					this.showGame = true;
				}
			},
			//跳转网址
			ToUrl(url) {
				window.open(url, "_blank");
			},
			submit() {
				console.log(JSON.stringify(this.finalMap))
				this.$axios({
					method: "POST",
					url: "https://cn.conspit.com/storeOrder",
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					data: {
						info: JSON.stringify(this.finalMap),
						price: this.priceSum,
						name: 'F1座舱模拟器套装',
					}
				}).then((response) => {
					console.log(response);
					let order = response.data.data
					window.open(order, '_blank');
				});
			},
			toPath(i){
				localStorage.setItem("map",JSON.stringify(this.finalMap))
				location.href="/#/p"+i+"?from=1"
			},
			_isMobile() {
				let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
				return flag;
			}
		},
		mounted() {
			this.priceSum =
				this.priceSum +
				this.item[this.c1].priceBefore +
				this.handBrake[this.brakeIndex].priceBefore + // 座舱
				this.pedestal[this.c2].priceBefore +
				this.directionwheel[this.c3].priceBefore + 5000;
			
			if (this._isMobile()) {
				console.log("手机端");
				location.href="https://cn.conspit.com/store?action=p4_m"
			} else {
				console.log("pc端");
			}
			
			if(this.$route.query.from == '1'){
				let map = JSON.parse(localStorage.getItem("map"))
				
				if(map['踏板选配'][0]['name'] == '标配CGP压感震动双踏板' || map['踏板选配'][0]['name'] == 'CGP压感震动双踏板' || map['踏板选配'][0]['name'] == '标配CGP压感震动双踏板（无搁脚底板）'){
					this.select(0);
				}else if(map['踏板选配'][0]['name'] == '升级CGP压感震动三踏板' || map['踏板选配'][0]['name'] == 'CGP压感震动三踏板'){
					this.select(0);
				}else if(map['踏板选配'][0]['name'] == '升级CPP油压震动双踏板' || map['踏板选配'][0]['name'] == 'CPP油压震动双踏板' || map['踏板选配'][0]['name'] == '标配CPP油压震动双踏板' || map['踏板选配'][0]['name'] == '升级CPP油压震动双踏板（无搁脚底板）'){
					this.select(1);
				}else if(map['踏板选配'][0]['name'] == '升级CPP油压震动三踏板（推荐）' || map['踏板选配'][0]['name'] == '标配CPP油压震动三踏板（推荐）'){
					this.select(1);
				}
				
				for(let ss=0; ss < map['手刹选配'].length; ss++){
					for(let nn=0; nn< this.handBrake.length;nn++){
						if(this.handBrake[nn]['name'] == map['手刹选配'][ss]['name']){
							this.selectHandbrake(nn)
						}
					}
				}
				
				for(let ss=0; ss < map['支架及配件'].length; ss++){
					for(let nn=0; nn< this.bracket.length;nn++){
						if(this.bracket[nn]['name'] == map['支架及配件'][ss]['name']){
							this.handleSelect(nn)
						}
					}
				}
				
				for(let nn=0; nn< this.pedestal.length;nn++){
					if(this.pedestal[nn]['name'] == map['基座配置'][0]['name']){
						this.selectPedestal(nn)
					}
				}
				for(let nn=0; nn< this.directionwheel.length;nn++){
					if(this.directionwheel[nn]['name'] == map['方向盘配置'][0]['name']){
						this.selectDirection(nn)
					}
				}
				if(map['显示器配置'].length > 0){
					for(let nn=0; nn< this.screen.length;nn++){
						if(this.screen[nn]['name'] == map['显示器配置'][0]['name']){
							this.selectScreen(nn)
						}
					}
				}
				if(map['电脑配置'].length > 0){
					for(let nn=0; nn< this.configure.length;nn++){
						if(this.configure[nn]['name'] == map['电脑配置'][0]['name']){
							this.chooseConfigure(nn)
						}
					}
				}
				for(let ss=0; ss < map['配件及服务'].length; ss++){
					for(let nn=0; nn< this.serve.length;nn++){
						if(this.serve[nn]['name'] == map['配件及服务'][ss]['name']){
							this.changeCheck(nn)
						}
					}
				}
				//this.changeCheck(0)
				for(let ss=0; ss < map['游戏选择'].length; ss++){
					for(let nn=0; nn< this.game.length;nn++){
						if(this.game[nn]['name'] == map['游戏选择'][ss]['name']){
							this.changeGame(nn)
						}
					}
				}
			}
			localStorage.removeItem('map')
		},
	};
</script>

<style scoped>
	.icon-Raidobox-xuanzhong{
		cursor: pointer;
	}
	
	.main {
		width: 1200px;
		height: calc(100vh - 70px - 145px);
		margin-left: calc((100% - 1200px) / 2);
		/* background-color: pink; */
	}

	.main_title {
		width: 100%;
		height: 75px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.main_left {
		font-size: 19px;
		font-weight: 600;
	}

	.title_middle {
		color: #fff;
		font-size: 16px;
		width: 280px;
		height: 35px;
		display: flex;
		justify-content: space-between;
	}

	.title_left,
	.title_right {
		cursor: pointer;
		width: 100%;
		height: 100%;
		line-height: 35px;
		text-align: center;
		color: #fff;
	}

	.title_left {
		background: #a71717;
	}

	.title_right {
		background: #31333b;
	}

	.main_body {
		width: 100%;
		height: 100%;
		display: flex;
	}

	.main_left_ {
		flex: 50%;
		width: 50%;
		display: flex;
		flex-direction: column;
		margin-right: 30px;
	}

	.swiper {
		width: 100%;
		height: 350px;
	}

	::v-deep .el-carousel__button {
		opacity: 10;
		width: 10px;
		height: 10px;
		border: none;
		border-radius: 50%;
		background-color: #fff;
	}

	::v-deep .is-active .el-carousel__button {
		background: #ee2424;
	}

	.icon-xiangzuo {
		cursor: pointer;
	}

	.left_content,
	.left_standard,
	.left_tip {
		font-size: 14px;
		width: 100%;
		margin: 0 auto;
		height: 38px;
		width: 530px;
		line-height: 38px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.left_standard {
		width: 90%;
		height: 45px;
		line-height: 45px;
		font-size: 16px;
		font-weight: 600;
		border-bottom: 1px solid #959595;
	}

	.left_content {
		font-size: 12px;
		width: 90%;
		display: flex;
		justify-content: space-between;
	}

	.configure {
		flex: 40%;
		width: 100%;
		padding: 0 10px 0 10px;
		box-sizing: border-box;
		overflow-x: hidden;
	}
	
	::-webkit-scrollbar {
	  /*滚动条整体样式*/
	  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
	  height: 1px;
	}
	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 15px;
		box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
		background: #a71717;
	}
	::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
		border-radius: 15px;
		background: #ededed;
	}

	.tip_content {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		width: 300px;
		height: 100%;
		font-size: 12px;
		border: 1px solid #959595;
		background: #f5f5f5;
	}

	.tip_icon {
		width: 17px;
		height: 17px;
		margin-right: 10px;
	}

	.item_one {
		width: 100%;
		margin-bottom: 30px;
	}

	.item_title {
		padding-right: 10px;
		font-size: 15px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #959595;
	}

	.item_content {
		width: 100%;
		margin-bottom: 40px;
	}

	.content_title {
		width: 100%;
		display: flex;
	}

	::v-deep .el-icon-arrow-right,
	::v-deep .el-icon-arrow-left {
		font-size: 20px;
	}

	.title_one:first-child {
		border-left: 1px solid #959595;
	}

	.activeColor {
		color: #a71717;
		border-bottom: 1px solid #a71717;
	}

	.item:last-child {
		margin-bottom: 30px;
	}

	.title_one {
		cursor: pointer;
		z-index: 999;
		margin: 6px 0;
		height: 22px;
		padding: 0 8px;
		box-sizing: border-box;
		font-size: 13px;
		border-right: 1px solid #959595;
	}

	.default {
		font-size: 15px;
		margin: 10px 0 10px 0;
	}

	.color_box {
		width: 70%;
		display: flex;
		flex-wrap: wrap;
	}

	.color_,
	.color_bracket {
		position: relative;
		display: flex;
		justify-content: center;
	}

	.color__item__big,
	.color_item_big,
	.customized_big,
	.color_item_second_big,
	.color_item_second_big_,
	.color_bracket_big {
		transform: scale(1.1, 1.1);
		box-shadow: 4px 4px 6px #8f8f8f;
	}

	.color__item__big {
		z-index: 9;
	}

	.color_item_,
	.color__item__big {
		background-size: 100%;
		background-image: url("../assets/color/blackRed.png");
	}

	.color_item,
	.color_item_,
	.customized,
	.customized_big,
	.color_item_big,
	.color__item__big,
	.color_item_second,
	.color_item_second_big,
	.color_item_second_,
	.color_item_second_big_ {
		position: relative;
		left: 0;
		width: 70px;
		cursor: pointer;
		height: 70px;
	}

	.color_bracket_,
	.color_bracket_big {
		margin-left: 2px;
		position: relative;
		left: 0;
		width: 45px;
		height: 40px;
		margin-right: 2px;
		background: #000;
		cursor: pointer;
	}

	.color_item_second,
	.color_item_second_big {
		margin-left: -2px;
		background: #ae823b;
		cursor: pointer;
	}

	.customized,
	.customized_big {
		background: #cbd4da;
		margin-left: 10px;
		cursor: pointer;
	}

	.color_item_second_,
	.color_item_second_big_ {
		background: #a71717;
		cursor: pointer;
	}

	.circular {
		position: absolute;
		bottom: 5px;
		right: 5px;
		width: 20px;
		height: 20px;
		line-height: 18px;
		background: #a71717;
		border: 1px solid #fff;
		border-radius: 30px;
		text-align: center;
	}

	.true {
		font-size: 15px;
	}

	.color_tip_,
	.color_tip {
		position: absolute;
		top: -8%;
		padding: 3px;
		box-sizing: border-box;
		background: #fff;
		border: 1px solid #bbb;
		z-index: 99;
		font-size: 10px;
		text-align: center;
		cursor: pointer;
	}

	.color_tip {
		left: 10%;
		width: 60px;
		cursor: pointer;
	}

	.color_tip_ {
		left: 20%;
		cursor: pointer;
	}

	.triangle {
		z-index: 99999;
		position: absolute;
		left: 40%;
		top: 18px;
		width: 0;
		height: 0;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		border-top: 5px solid #fff;
	}

	.item {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100px;
		border-bottom: 1px solid #959595;
	}

	.activebtn {
		color: #ee2424;
	}

	.icon-Raidobox-xuanzhong {
		margin-left: 20px;
		color: #b7b6b6;
	}

	.icon-tishi {
		margin-right: 5px;
	}

	.item_right {
		font-size: 14px;
		text-align: right;
	}

	::v-deep .el-checkbox,
	::v-deep .el-checkbox__label {
		color: #333;
		width: 97%;
	}

	input[type="checkbox"] {
		width: 17px;
		height: 17px;
		margin-top: 2px;
		position: relative;
	}

	input[type="checkbox"]::after {
		position: absolute;
		top: 0;
		color: #000;
		display: inline-block;
		visibility: visible;
		padding-left: 0px;
		text-align: center;
		content: " ";
		border-radius: 2px;
	}

	input[type="checkbox"]:checked::after {
		content: "✓";
		color: #a71717;
		font-size: 20px;
		line-height: 15px;
		border: 1px solid #333;
		background-color: #fff;
	}

	.item_one:last-child {
		margin-bottom: 40px;
	}

	.price_btn {
		margin-right: 15px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		width: 110px;
		height: 32px;
		color: #fff;
		border: 1px solid #bbb;
		background: #444444;
	}

	.icon-four-squares-full {
		margin-right: 4px;
		color: #fff;
	}

	.configure_title {
		width: 100%;
		height: 40px;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #333;
	}

	.price_item {
		width: 100%;
		padding: 20px 0 10px 0;
		border-bottom: 1px solid #333;
	}

	.price_item:last-child {
		margin-bottom: 20px;
	}

	.option_item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
	}
</style>
